<template>
    <div class="page">
        <div style="background-color: #FFFFFF;">
            <div style="color: black;font-weight: bold;display: flex; align-items: center;"
                class="font-size16 main-title">
                创建/编辑/续租/复制长租订单
            </div>
            <div class="border-radius4 main-back" style="background: #FFFFFF;display: flex;">
                <el-button type="primary" size="small" @click="goBack">返回</el-button>
            </div>
            <meta content="no-referrer" name="referrer" />
            <div class="content-box section">
                <easy-card title="基础信息">
                    <el-form ref="form" :model="model" :rules="rules" label-width="80px" size="small"
                        label-position="left" style="padding: 0 20px;">

                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="选择车场" prop="parkingLotId" required>
                                    <el-col :span="20">
                                        <SuggestParking v-model="model.parkingLotId" @parkingName="onChangeParkingLot" />
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="选择套餐" prop="leaseId" required>
                                    <el-col :span="20">
                                        <el-select v-model="model.leaseId" placeholder="请选择套餐" style="width: 100%;" clearable>
                                            <el-option v-for="item, index in dataList" :key="index" :value=item.id
                                                :label="item.leaseName">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="主车牌" prop="firstPlateNumber" required>
                                    <div style="display: flex;">
                                        <el-select v-model="model.firstPlateColor" placeholder="牌照"
                                            style="width: 100px;">
                                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-input v-model="model.firstPlateNumber" placeholder="请输入车牌号"
                                            style="width: 150px;"></el-input>
                                        <el-button type="primary" @click="addFuNum" v-if="!showSecond">添加副车牌</el-button>
                                    </div>

                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row>
                            <el-col :span="12" v-if="showSecond">
                                <el-form-item label="副车牌" prop="secondPlateNumber">
                                    <div style="display: flex;">
                                        <el-select v-model="model.secondPlateColor" placeholder="牌照"
                                            style="width: 100px;">
                                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-input v-model="model.secondPlateNumber" placeholder="请输入车牌号"
                                            style="width: 150px;"></el-input>
                                        <el-button type="primary" @click="delFuNum()">删除</el-button>
                                    </div>

                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- <el-row v-if="fuList.length > 0">
                        <el-col :span="12" v-for="item, index in fuList" :key="index">
                            <el-form-item label="副车牌" prop="regionId" required>
                                <div style="display: flex;">
                                    <el-select v-model="item.type" placeholder="牌照" style="width: 100px;">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                    </el-select>
                                    <el-input v-model="item.num" placeholder="请输入车牌号" style="width: 150px;"></el-input>
                                    <el-button type="primary" @click="delFuNum(index)">删除</el-button>
                                </div>

                            </el-form-item>
                        </el-col>
                    </el-row> -->
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="购买数量" prop="buyCount" required>
                                    <el-col :span="20">
                                        <el-input-number v-model="model.buyCount" controls-position="right"
                                            :min="1" style="width: 100%;"></el-input-number>
                                    </el-col>

                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="生效日期" prop="timeValues" required>
                                    <el-date-picker v-model="model.timeValues" align="right" end-placeholder="结束时间"
                                        range-separator="-" start-placeholder="开始时间" type="datetimerange"
                                        @change="changeDate" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="订单金额" prop="orderAmount" required>
                                    <el-col :span="20">
                                        <el-input v-model="model.orderAmount" placeholder="请输入订单金额"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="车位号" prop="spaceCode">
                                    <el-col :span="20">
                                        <el-input v-model="model.spaceCode" placeholder="请输入车位号"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </easy-card>

                <!-- <div class="separate-line"></div> -->

                <easy-card title="选填信息">
                    <el-form ref="form2" :model="model" :rules="rules" label-width="100px" size="small"
                        label-position="left" style="padding: 0 20px;">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="联系人" prop="contacts">
                                    <el-col :span="20">
                                        <el-input v-model="model.contacts" placeholder="联系人"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系电话" prop="phone">
                                    <el-col :span="20">
                                        <el-input v-model="model.phone" placeholder="请输入联系电话"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="所属单位" prop="unit">
                                    <el-col :span="20">
                                        <el-input v-model="model.unit" placeholder="请输入所属单位"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="备注" prop="remark">
                                    <el-col :span="20">
                                        <el-input v-model="model.remark" placeholder="请输入备注"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div style="display: flex;">
                            <!-- <el-button @click="save">取消
                        </el-button> -->
                            <el-button class="xxd-button_save" type="primary" @click="save">保存
                            </el-button>
                        </div>


                    </el-form>
                </easy-card>
            </div>
            <SuccessDialog v-model="showDialog" @closeDialog="closeSuccDialog" :title="'提示'" :tip="successTip" />
        </div>
    </div>
</template>


<script>
import SuggestParking from "@/components/SuggestParking";
import {
    getLeaseOrderInfo,
    addLeaseOrder,
    editLeaseOrder
} from "@/api/lease"
import {
    getLeaseList
} from "@/api/common";
import SuccessDialog from "@/components/SuccessDialog";
import { Message } from "element-ui";

export default {
    data() {
        return {
            isLoading: false,
            dataList: [],
            ParkingLotId: '',
            orderDetatil: {}, //从上个页面获取到的订单信息
            orderInfoPlus: {},// 从接口获取的订单详情补充信息
            driveInPhotoList: [],
            driveOutPhotoList: [],
            inImgDataList: [], //路内停车驶入图片
            outImageDataList: [],//
            pictureDialogFormVisible: false, //查看图片弹窗
            businessDetailDialogFormVisible: false, //查看业务详情弹窗
            driveInTableData: [], //驶入记录
            driveOutTableData: [], //驶离记录
            payRecordTableData: [], //支付记录
            orderType: {
                "0": "临停",
                "1": "白名单",
                "2": "黑名单",
                "3": "长租",
                "4": "预存",
                "5": "访客",
                "6": "共享"
            },
            orderStatus: {
                "0": "正常",
                "1": "无效",
                "2": "审核",
                "3": "复议",
                "": "正常",
            },
            options: [
                // {
                //     value: "0",
                //     label: "通用",
                //     disabled: false,
                // },
                {
                    value: "01",
                    label: "黄牌",
                    disabled: true,
                },
                {
                    value: "02",
                    label: "蓝牌",
                    disabled: true,
                },
                {
                    value: "06",
                    label: "黑牌",
                    disabled: true,
                },
                {
                    value: "03",
                    label: "新能源",
                    disabled: true,
                },
            ],
            model: {
                parkingLotId:"",
                leaseId:"",
                firstPlateNumber:"",
                buyCount:"",
                timeValues:"",
                orderAmount:""
            },
            rules: {
                parkingLotId: [{ required: true, message: "停车场名称不能为空" }],
                leaseId: [{ required: true, message: "长租套餐不能为空" }],
                firstPlateNumber: [{ required: true, message: "主车牌不能为空" }],
                buyCount: [{ required: true, message: "购买数量不能为空" }],
                timeValues: [{ required: true, message: "生效时间不能为空" }],
                orderAmount: [{ required: true, message: "订单金额不能为空" }],

            },
            fuList: [],
            showSecond: true,
            showDialog: false,
            successTip: ""
        };
    },
    components: {
        SuggestParking,
        SuccessDialog
    },
    methods: {
        closeSuccDialog(e) {
            console.log(e);
            this.showDialog = false;
        },
        //返回停车订单页面
        goBack() {
            this.$router.back();
        },
        addFuNum() {
            this.showSecond = true;
        },
        changeDate(e) {
            this.model.startTime = e[0];
            this.model.endTime = e[1];
        },
        delFuNum() {
            this.showSecond = false;
            this.model.secondPlateNumber = "";
            this.model.secondPlateColor = "";
        },
        //查看停车图片
        checkPicture() {
            this.pictureDialogFormVisible = true;
        },
        checkBusinessDetail() {
            this.businessDetailDialogFormVisible = true;
        },
        //查看证件图片
        checkIdPicture() {
            this.$message({
                message: '暂无图片信息',
                type: 'warning'
            });
        },
        //查看放行凭证
        checkReleasePicture() {
            this.$message({
                message: '暂无图片信息',
                type: 'warning'
            });
        },
        onChangeParkingLot(e) {
            // this.$set
            this.model.leaseId = "";
            getLeaseList({ pageNo: 1, pageSize: 100, parkingLotId: e.id }).then(res => {
                if (res && res.code == 30) {
                    this.dataList = res.returnObject;
                }
            })
        },
        save() {      
            if ((!this.model.secondPlateNumber && this.model.secondPlateColor) || (this.model.secondPlateNumber && !this.model.secondPlateColor)) {
                Message.info("车牌颜色跟车牌号需要同时填写");
                return;
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.$route.query.orderId && this.$route.query.type == 0) {
                        editLeaseOrder(this.model).then(res => {
                            if (res && res.code == 30) {
                                // Message.success(res.message);
                                this.showDialog = true;
                                this.successTip = "编辑订单成功";
                            }
                        })
                    } else {
                        addLeaseOrder(this.model).then(res => {
                            if (res && res.code == 30) {
                                // Message.success(res.message);
                                this.showDialog = true;
                                this.successTip = "操作成功";
                            }
                        })
                    }
                }
            })
        }
    },
    async mounted() {
        const res = await getLeaseList({ pageNo: 1, pageSize: 100 });
        if (res && res.code == 30) {
            this.dataList = res.returnObject;
        }
        if (this.$route.query.orderId) {
            getLeaseOrderInfo(this.$route.query).then(res => {
                if (res && res.code === 30 && res.result) {
                    const returnObject = res.returnObject;
                    this.model = { ...returnObject, ...returnObject.plate, timeValues: [returnObject.startTime, returnObject.endTime] };

                    // this.model.timeValues =  [returnObject.startTime,returnObject.endTime];     
                }
            });
        }

    },
};
</script>

<style lang="less" scoped>
.record-tilte {
    margin: 14px 0;
}

.span-margin {
    margin-left: 4px;
}

.showPicture {
    display: flex;
    flex-direction: row;
}

.pictureBox {
    border: 1px solid #bbb;
    width: 100%;
}

.boxF1 {
    margin-right: 10px;
}

.boxF2 {
    margin-left: 10px;
}

.carNumber {
    width: 100%;
    line-height: 30px;
    text-align: center;
    background: #0768FD;
    color: #fff;
}

.inOrOutTime {
    background: #f0f1f5;
    // padding-left: 15px;
    width: 100%;
    display: inline-block;
    line-height: 30px;
}

.photo-width {
    margin: 12px 8px;

    .photo-item {
        width: 100%;
        //display: flex;

        .top-img {
            //width: 284px;
            //height: 184px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.table-list {
    margin-top: 20px;
}

/deep/ .el-button--text {
    color: #0768FD !important;
}

/deep/ .el-input-number .el-input__inner{
    text-align: left;
}

.main-title {
    padding-left: 18px;
    padding-top: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #FAFAFA;
}

.main-back {
    padding-left: 18px;
    margin-top: 20px;
    padding-bottom: 2px;
}
</style>